/**
 * FAQ section generates the section UI for FAQs, as well as inserts structured
 * data json into the page head.  Thus, it should only be used one time on each page/view
 */

import React, { Fragment, useState } from "react";
import styled, { css } from "styled-components";
import Helmet from "react-helmet";
import { Text, Box, Flex, Icon, openIntercom, Button } from "@thenounproject/lingo-core";

import { Container, InnerMedium } from "./Elements";
import MarketingText from "./MarketingText";
import TextLink from "./TextLink";

type FAQProps = {
  question: string;
  answer: string;
  link?: { text: string; link: string };
};

type Props = {
  title?: string;
  faqs: FAQProps[];
};

const FaqSection: React.FC<Props> = ({ title = "Frequently asked questions", faqs }) => {
  const createStructuredData = () => {
    const createFaqDataNodes = () => {
      return faqs.map(f => {
        return `{"@type":"Question","name":"${f.question}","acceptedAnswer":{"@type":"Answer","text":"${f.answer}"}}`;
      });
    };
    return `{"@context":"https://schema.org","@type":"FAQPage","mainEntity":[${createFaqDataNodes()}]}`;
  };
  return (
    <Fragment>
      <Helmet>
        <script type="application/ld+json">{createStructuredData()}</script>
      </Helmet>
      <Container my="xxl">
        <InnerMedium flexDirection="row" variations={{ "mq.m": { flexDirection: "column" } }}>
          <Box mb="xl" flex="2" variations={{ "mq.m": { textAlign: "center" } }}>
            <Text as="h3" font="marketing.h3">
              {title}
            </Text>
          </Box>
          <Box m="m" />
          <Flex flex="3" flexDirection="column">
            {faqs.map(faq => (
              <FaqBlock key={faq.answer} {...faq} />
            ))}
            <Box mt="l">
              <Text font="marketing.regular">
                More questions? Visit our{" "}
                <Button
                  size="regular"
                  buttonStyle="tertiary"
                  text="help center"
                  fontStyle="marketing.regular"
                  link={"https://help.lingoapp.com"}
                  newWindow
                />{" "}
                or{" "}
                <Button
                  size="regular"
                  buttonStyle="tertiary"
                  text="contact us"
                  fontStyle="marketing.regular"
                  onClick={() => openIntercom()}
                />
              </Text>
            </Box>
          </Flex>
        </InnerMedium>
      </Container>
    </Fragment>
  );
};

const ToggleButton = styled.button<{ open: boolean }>`
  display: flex;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  padding: 0;

  &:focus {
    outline: none;
  }

  svg {
    transition: all 0.4s ease;
    transform: rotate(0deg);
    flex-shrink: 0;
  }

  ${props =>
    props.open &&
    `
    svg {
      transform: rotate(180deg);
    }
  `}
`;

const FaqBlock: React.FC<FAQProps> = ({ question, answer, link }) => {
  const [open, setOpen] = useState(false);
  return (
    <Flex flexDirection="column" borderBottom="1px solid grayLight" mb="l">
      <Flex alignItems="flex-start" justifyContent="space-between">
        <ToggleButton open={open} onClick={() => setOpen(!open)}>
          <Text font="marketing.subtitle1" mb="s">
            {question}
          </Text>
          <Icon size={24} ml="m" iconId="arrow-down" />
        </ToggleButton>
      </Flex>
      <Box
        mb="l"
        // arbitrary max-height required for animation
        maxHeight={!open ? "0" : "220px"}
        css={css`
          transition: all 0.4s ease;
          overflow: hidden;
          opacity: ${!open ? "0" : "1"};
        `}>
        <MarketingText>{answer}</MarketingText>
        {link && <TextLink mt="m" {...link} />}
      </Box>
    </Flex>
  );
};

export default FaqSection;
